@import '../../../styles.scss';

.header {
    z-index: 3;
    background-color: #fff;
    text-align: center !important;
    flex: 20;
    flex-direction: row;

    button.ant-btn-link:hover,
    .Header .ant-dropdown-open {
        background-color: #e9e9e9;
    }
}

.ant-menu-horizontal{
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

}

.ant-menu-horizontal > .ant-menu-item-selected {
}

.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-horizontal > .ant-menu-item-selected a:hover,
.ant-menu-horizontal > .ant-menu-item a:hover,
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #fff !important; 
  font-weight: 600;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  border-bottom: 2px solid #fff !important
}

.ant-menu {
  text-align: center;
}


.ant-menu-item {
  color: #fff !important;

  span {
    a {
      color: #fff !important;
      font-weight: 600
    }
  }
}

.ant-menu-item:hover, 
.ant-menu-item-active {
  color: #fff !important;
  border-bottom: 2px solid rgba(255,0,0,0) !important;
}

.ant-menu-submenu-title {
   color: #fff
}

.ant-menu-vertical.ant-menu-sub:not([class*='-active']), .ant-menu-vertical-left.ant-menu-sub:not([class*='-active']), .ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
  background-color: #ff0000;
}

.ant-menu-item-selected {
  background-color: #ff0000 !important;
}
