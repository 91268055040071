@import '../../../../styles.scss';

.pitchWrapper {
    margin-top: 150px;
    z-index: 2;
    width: 100%;
   
    .section {
        flex: 1;
        display: flex;
        align-items: center;
        height: 100%;
    }

    .aboutProduct {
        margin-left: 15%;
        justify-content: flex-start;
        width: 35%;
    }

    .pictureProduct {
        margin-right: 20%;
        margin-top: -230px;
        justify-content: flex-end;
    }
}

.ant-collapse {
    .ant-collapse-item {
        border-bottom: 0px !important;

        .ant-collapse-header {
            color: #fff !important;
            font-weight: 600;
            border-radius: 8px !important;
        }
    }
    border-radius: 4px;
    background-color: #ff0000 !important;
    border: 0px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.4);
}

.ant-collapse-content-active{
    border-left: 0px !important;
}

.anticon-plus-square, .anticon-minus-square {
    font-size: 18px !important;
    margin-bottom: 4px !important;
}

.ant-collapse-content {
    border: 0px !important
}

.ant-ribbon.ant-ribbon-placement-start {
    font-weight: 600;
    top: -12px;
}

.ant-image {
    align-self: center;
}