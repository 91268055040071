@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');


*{
  font-family: 'Poppins';
}

*::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "Arvo-BoldItalic";
  src: url("./font/Arvo/Arvo-BoldItalic.ttf");
}
@font-face {
  font-family: "Arvo-Bold";
  src: url("./font/Arvo/Arvo-Bold.ttf");
}
@font-face {
  font-family: "Arvo-Italic";
  src: url("./font/Arvo/Arvo-Italic.ttf");
}
@font-face {
  font-family: "Arvo-Regular";
  src: url("./font/Arvo/Arvo-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-Black";
  src: url("./font/Montserrat/Montserrat-Black.ttf");
}

@font-face {
  font-family: "Montserrat-BlackItalic";
  src: url("./font/Montserrat/Montserrat-BlackItalic.ttf");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./font/Montserrat/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat-BoldItalic";
  src: url("./font/Montserrat/Montserrat-BoldItalic.ttf");
}
@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("./font/Montserrat/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: "Montserrat-ExtraBoldItalic";
  src: url("./font/Montserrat/Montserrat-ExtraBoldItalic.ttf");
}
@font-face {
  font-family: "Montserrat-Light";
  src: url("./font/Montserrat/Montserrat-Light.ttf");
}
@font-face {
  font-family: "Montserrat-LightItalic";
  src: url("./font/Montserrat/Montserrat-LightItalic.ttf");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("./font/Montserrat/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat-MediumItalic";
  src: url("./font/Montserrat/Montserrat-MediumItalic.ttf");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("./font/Montserrat/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./font/Montserrat/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Montserrat-SemiBoldItalic";
  src: url("./font/Montserrat/Montserrat-SemiBoldItalic.ttf");
}
@font-face {
  font-family: "Montserrat-Thin";
  src: url("./font/Montserrat/Montserrat-Thin.ttf");
}

@font-face {
  font-family: "Montserrat-ThinItalic";
  src: url("./font/Montserrat/Montserrat-ThinItalic.ttf");
}


// VARIABLES
$image_sheperd: url('./res/img/shp2.jpg');


// CLASSES

.mask-image{
  mask-image: linear-gradient(to right, transparent 0%, black 90%);
  background-size: cover;
}

.ant-btn-primary {
  background-color: red;
  border-color: red;
  border-radius: 2px;
}


.ant-btn-primary:hover {
  background-color: rgb(255, 75, 75);
  border-color: rgb(255, 75, 75);
  color: white;
  border-radius: 2px;
}

.ant-btn-primary:focus {
  background-color: red;
  border-color: red;
  color: white;
  border-radius: 2px;
}
